import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"

const NotFoundPage = () => {
  return (
   <Layout>
     <h1 className="font-display text-2xl">Sorry we couldn't find what you were looking for</h1>
   </Layout>
  )
}

export default NotFoundPage
